/* istanbul ignore file */
import Address from '../../interfaces/IAddress';
import Name from '../../interfaces/IName';
import { Request } from '../../interfaces/IRequest';
import { LoanMilestones } from '../loans/types';

export const LOAN_ATTRIBUTES = 'loan-attributes';
export const userAttributeKey = [LOAN_ATTRIBUTES] as const;
export type UserAttributeKey = (typeof userAttributeKey)[number];

export const LANGUAGE_PREFERENCE = 'language-preference';
export const userKey = [LANGUAGE_PREFERENCE] as const;
export type UserKey = (typeof userKey)[number];

export interface User {
  address?: Address;
  email: string;
  name?: Name;
  phone?: string;
  guid: string;
  'ssn?': boolean;
  'language-preference'?: string;
  'current-lo'?: number;
}

export interface UserEntity extends Request {
  data: User | null;
}

export interface LoanAttributes {
  [index: string]: unknown;
  'docs-last-viewed'?: number;
  firstVisitAt?: string;
  tasksReadyAt?: string;
  welcomeShown?: string;
  fastTrackShown?: string;
  'preapproval-congrats-shown'?: string;
  milestoneModalsShown?: {
    [key in LoanMilestones]?: string;
  };
}

export interface LoanAttributesMap {
  [index: string]: LoanAttributes;
}

export interface UserAttributesMap {
  [index: string]: unknown;
  [LOAN_ATTRIBUTES]: LoanAttributesMap;
}

export interface LoanAttributesEntity extends Request {
  data: UserAttributesMap | Record<string, never>;
}

export interface UserState {
  user: UserEntity;
  attributes: LoanAttributesEntity;
  docsPreviouslyLastViewed: Record<string, number>;
}
