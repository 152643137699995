import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../store/types';
import { useAppSelector } from '../../hooks/hooks';
import type { AppState, GlobalError, NavigationBlocker } from './types';
import { selectIsAuth, selectIsAuthValidated } from '../auth/authSlice';
import { selectHasUser, selectHasUserAttribute } from '../user/userSlice';
import { LOAN_ATTRIBUTES } from '../user/types';
import { selectHasConfig } from '../config/configSlice';
import { selectLoansEntity } from '../loans/loansSlice';
import { setDataLayer } from '../analytics/setDataLayer';
import { getCookieValue } from '../../utils/getCookieValue';
import { setCookie } from '../../utils/setCookie';
import { getBaseDomain } from '../../utils/getBaseDomain';
import { uuid } from '../../utils/uuid';

const namespace = 'app';

const sessionId = uuid();

export const updateGlobalLoadingOnce =
  (isLoading: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (selectGlobalLoading(state) !== isLoading) {
      dispatch(updateGlobalLoading(isLoading));
    }
  };

const initialState: AppState = {
  sessionId,
  global: {
    error: undefined,
    notFound: false,
    loading: true,
    embed: false,
    adtrk: undefined,
    cookiedLoId: undefined,
    mobileApp: false,
    language: null,
    cobrowseInSession: false,
    online: true,
  },
  navigationBlockers: [],
};

export const appSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    updateGlobalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.global.loading = payload;
    },
    updateGlobalError: (state, { payload }: PayloadAction<GlobalError | undefined>) => {
      state.global.error = payload;
    },
    updateNotFound: (state, { payload }: PayloadAction<boolean>) => {
      state.global.notFound = payload;
    },
    updateEmbedMode: (state, { payload }: PayloadAction<boolean>) => {
      state.global.embed = payload;
    },
    updateAdtrk: (state, { payload }: PayloadAction<string>) => {
      state.global.adtrk = payload;
    },
    updateCookiedLoId: (state, { payload }: PayloadAction<string>) => {
      state.global.cookiedLoId = payload ? parseInt(payload) : undefined;
    },
    updateMobileAppMode: (state, { payload }: PayloadAction<boolean>) => {
      state.global.mobileApp = payload;
    },
    updateAppLanguage: (state, { payload }: PayloadAction<string>) => {
      state.global.language = payload;
      setDataLayer('languagePreference', payload);
    },
    addNavigationBlocker: (state, { payload }: PayloadAction<NavigationBlocker>) => {
      const include = state.navigationBlockers.some(blocker => blocker.id === payload.id);
      if (!include) {
        state.navigationBlockers = [...state.navigationBlockers, payload];
      }
    },
    removeNavigationBlocker: (state, { payload }: PayloadAction<NavigationBlocker>) => {
      const blockers = [...state.navigationBlockers];
      state.navigationBlockers = blockers.filter(blocker => blocker.id !== payload.id);
    },
    removeNavigationBlockers: state => {
      const blockers = [...state.navigationBlockers];
      state.navigationBlockers = blockers.filter(blocker => !!blocker.persist);
    },
    clearNavigationBlockers: state => {
      state.navigationBlockers = [];
    },
    updateCobrowseInSession: (state, { payload }: PayloadAction<boolean>) => {
      state.global.cobrowseInSession = payload;
    },
    updateGlobalOnline: (state, { payload }: PayloadAction<boolean>) => {
      state.global.online = payload;
    },
  },
});

export const {
  updateGlobalLoading,
  updateGlobalError,
  updateNotFound,
  updateEmbedMode,
  updateAdtrk,
  updateCookiedLoId,
  updateMobileAppMode,
  updateAppLanguage,
  updateGlobalOnline,
  addNavigationBlocker,
  removeNavigationBlocker,
  removeNavigationBlockers,
  clearNavigationBlockers,
  updateCobrowseInSession,
} = appSlice.actions;

export const selectAppState = (state: RootState) => state.app;
export const selectSessionId = (state: RootState) => selectAppState(state).sessionId;
export const selectGlobalLoading = (state: RootState) => selectAppState(state).global.loading;
export const selectGlobalEmbed = (state: RootState) => selectAppState(state).global.embed;
export const selectGlobalAdtrk = (state: RootState) => selectAppState(state).global.adtrk;
export const selectGlobalCookiedLoId = (state: RootState): number | undefined => selectAppState(state).global.cookiedLoId;
export const selectGlobalMobileApp = (state: RootState) => selectAppState(state).global.mobileApp;
export const selectGlobalError = (state: RootState) => selectAppState(state).global.error;
export const selectGlobalNotFound = (state: RootState) => selectAppState(state).global.notFound;
export const selectGlobalOnline = (state: RootState) => selectAppState(state).global.online;
export const selectHasInitialData = (state: RootState) => selectHasConfig(state) && selectIsAuthValidated(state);
export const selectHasGlobalData = (state: RootState) => {
  const embed = selectGlobalEmbed(state);
  const hasInitialData = selectHasInitialData(state);
  const isAuth = selectIsAuth(state);
  const hasUserData = selectHasUser(state);
  const { hasData: hasLoansData } = selectLoansEntity(state);
  const hasUserLoanAttributes = selectHasUserAttribute(state, LOAN_ATTRIBUTES);
  return hasInitialData && (!isAuth || (isAuth && hasUserData && (embed || hasLoansData) && hasUserLoanAttributes));
};
export const selectNavigationBlockers = (state: RootState) => selectAppState(state).navigationBlockers;
export const selectAppLanguage = (state: RootState) => selectAppState(state).global.language;
export const selectCobrowseInSession = (state: RootState) => selectAppState(state).global.cobrowseInSession;

export const useHasGlobalError = () => {
  const hasGlobalError = useAppSelector(selectGlobalError);
  return hasGlobalError;
};

/** OneLinkJS lib requires a cookie to be set and then reload the browser */
export const reloadAppLanguage = (language: string) => {
  const langPrefCookie = getCookieValue('langPref');
  if (langPrefCookie !== language) {
    setCookie('langPref', language, { domain: getBaseDomain() });
    window.location.reload();
  }
};

export default appSlice.reducer;
