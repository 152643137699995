import { BorrowerAgent, Task } from '../../../features/tasks/types';
import Button from '../../ui/button/Button';
import TaskTitleBadge from '../task/TaskTitleBadge';
import { closeModal, openModal, setModalContent } from '../tasklist/TaskModal';
import AgentFormModalContent from './AgentFormModal';
import ModalDisclaimerContent from '../../ui/modal/ModalDisclaimerContent';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import { log } from '../../../utils/logger';
import ModalLoading from '../../ui/modal/ModalLoading';
import api from '../../../features/api';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import { serializeError } from '../../../utils/serializeError';
import { useAppDispatch } from '../../../hooks/hooks';
import { updateTask } from '../../../features/tasks/actions';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';
import { fetchLoanRealtor } from '../../../features/loans/loansSlice';

export const AGENT_TYPES = ['borrower-agent'] as const;

const CONSENT_TEXT = 'We understand you may be working with your real estate agent on the purchase of a home. Your agent has requested that we share your information, including preapprovals, the final approved appraisal, and the purchase contract, and the information will only be used for loan status monitoring. In an effort to allow for a smooth, coordinated financing and purchase process, Guaranteed Rate requires your consent to share information with your agent. Please let us know if it is acceptable for us to share your customer information, preapprovals, the final approved appraisal, and the purchase contract with your agent for the purpose of loan status monitoring. The sharing of the information will only be used for loan status monitoring through the loan process. By clicking accept, I consent and authorize Guaranteed Rate to share with my real estate agent, preapprovals, the final approved appraisal, and the purchase contract, for this information solely to be used for loan status monitoring purposes. You may opt-out of this information sharing with your real estate agent at any time.';

export const AgentConsentTaskBody = ({ loanGuid, task }: { loanGuid: string; task: Task<'borrower-agent'>} ) => {
  const { 'task-id': taskId, 'task-type': taskType } = task;
  const support = useDefaultSupport();
  const dispatch = useAppDispatch();

  const handleAgentConsent = async (agent: BorrowerAgent, accept: boolean | null) => {
    const updatedAgent = {
      ...agent,
      agree: accept,
    };

    setModalContent(taskId, <ModalLoading />);

    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'debug', message: `Consent: Attempting to update agent consent for ${taskType} task ${JSON.stringify({ accept })}` });
      await api.postRealEstateAgent(loanGuid, updatedAgent);
      setModalContent(
        taskId,
        <ConfirmationModalContent
          title='Your consent settings have been updated.'
          cancelButtonText='Back to your task list'
          onCancel={() => closeModal(taskId)}
        />
      );
      // update the virtual task to the posted agent
      dispatch(
        updateTask(taskId, {
          taskDetails: {
            'task-completed': true,
          },
          customTaskMeta: updatedAgent
        })
      );
      // Get latest real estate agent contact info
      dispatch(fetchLoanRealtor(loanGuid));
    } catch (err) {
      log({ loanGuid, taskId, level: 'error', message: `Update agent consent failed ${serializeError(err)}` });
      setModalContent(
        taskId,
        <ErrorModalContent
          title='We were unable to update your consent'
          support={support}
          location='your tasklist'
          onDone={() => closeModal(taskId)}
        />
      );
    }
  };

  const handleModalFormSubmit = (agent: BorrowerAgent) => {
    // if no-agent is checked, skip the consent modal and post a NULL agent
    if (agent['no-agent']) {
      handleAgentConsent({
        'first-name': null,
        'last-name': null,
        email: null,
        agree: null,
        'no-agent': true,
      }, null);
      return;
    }

    const agentName = agent['first-name'] && agent['last-name'] ? `${agent['first-name']} ${agent['last-name']}` : undefined;
    setModalContent(
      taskId,
      <ModalDisclaimerContent
        title={`Share your loan details with your real estate agent${agentName ? ` ${agentName}?` : '?'}`}
        disclaimer={CONSENT_TEXT}
        disclaimerShowCurrentDate={false}
        onAccept={() => handleAgentConsent(agent, true)}
        onDecline={() => handleAgentConsent(agent, false)}
      />
    );
  };

  const handleButtonClick = () => {
    log({ loanGuid, taskId, message: `Consent: ${taskType} task modal opened` });
    const modalComponent = <AgentFormModalContent task={task} onNext={handleModalFormSubmit}/>;
    openModal(taskId, modalComponent, `${taskType} Task`);
  };

  const buttonText = task['task-completed'] ? 'Edit' : 'Review';

  return (
    <Button className='task-button' buttonType='tertiary' title={`${buttonText} borrower agent task`} onClick={handleButtonClick}>
      {buttonText}
    </Button>
  );
};

export const AgentConsentTaskTitle = ({ task, taskTitle }: { task: Task<'borrower-agent'>, taskTitle: string }) => {
  const { agree, 'no-agent': noAgent } = task['custom-task-meta'];
  const badgetType = task['task-completed'] ? (agree === true ? 'accepted' : agree === false || noAgent ? 'declined' : null) : null;
  return <TaskTitleBadge title={taskTitle} badgeType={badgetType} />;
};
