import { CSSProperties } from 'react';
import { FadeProps } from './IFade';
import { iDirectionTypes, SlideProps } from './ISlide';
import { getDefaultStyles as getDefaultFadeStyles, transitionStyles as transitionFadeStyles } from './Fade';
import { getDefaultStyles as getDefaultSlideStyles, getTransitionStyles as getTransitionSlideStyles } from './Slide';
import Animation from './Animation';

interface FadeSlideProps extends FadeProps, SlideProps {}

const getDefaultStyles = (duration: number, direction: iDirectionTypes, distance: string): CSSProperties => ({
  ...getDefaultFadeStyles(duration),
  ...getDefaultSlideStyles(duration, direction, distance),
  transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
});

const getTransitionStyles = (direction: iDirectionTypes, distance: string): Record<string, CSSProperties> => {
  const transitionSlideStyles = getTransitionSlideStyles(direction, distance);
  return ['entering', 'entered', 'exiting', 'exited'].reduce(
    (acc, transitionId) => ({
      ...acc,
      [transitionId]: { ...transitionFadeStyles[transitionId], ...transitionSlideStyles[transitionId] },
    }),
    {},
  );
};

const FadeSlide = ({ on, duration = 300, directionFrom = 'up', distance = '100%', children, className }: FadeSlideProps) => (
  <Animation
    on={on}
    duration={duration}
    defaultStyle={getDefaultStyles(duration, directionFrom, distance)}
    transitionStyles={getTransitionStyles(directionFrom, distance)}
    className={className}
  >
    {children}
  </Animation>
);

export default FadeSlide;
