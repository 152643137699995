import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { AnimationProps, iStateTypes } from './IAnimation';

const Animation = ({ on, duration, children, className, defaultStyle, transitionStyles, ...rest }: AnimationProps) => {
  const nodeRef = useRef(null);
  return (
    <Transition in={on} timeout={duration} nodeRef={nodeRef} {...rest}>
      {(state: iStateTypes) => {
        return (
        <div
          className={className}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          ref={nodeRef}
        >
          {children}
        </div>
        );
      }}
    </Transition>
  );
};

export default Animation;
