import { useEffect, useState } from 'react';
import { useNetwork } from '../../hooks/useNetwork';
import Button from '../ui/button/Button';
import SlideOut from '../animations/SlideOut';

const OfflineHeader = () => {
  const online = useNetwork();
  const [on, setOn] = useState(!online);

  useEffect(() => {
    setOn(!online);
  }, [online]);

  const handleDismiss = () => {
    setOn(false);
  };

  return (
    <SlideOut on={on} duration={250}>
      <div className='bg-info-10 p-4 mb-4'>
        <p className='mb-2'>You're offline. Please reconnect to the internet.</p>
        <Button buttonType='tertiary' text='Dismiss' onClick={handleDismiss} />
      </div>
    </SlideOut>
  );
};

export default OfflineHeader;
