import { useEffect, useState } from 'react';
import Embed from '../../components/ui/embed/Embed';
import cn from 'classnames';
import { log } from '../../utils/logger';
import { computeDestinationPoint } from 'geolib';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';
import { content } from '../../features/tenant/tenant';
import { useAppSelector } from '../../hooks/hooks';
import { selectUserZip } from '../../features/user/userSlice';
import { useSavedSearches } from '../../features/homeSearch/homeSearchSlice';

const NewListings = ({ hidden = false, onReady}: { hidden?: boolean, onReady?: () => void }) => {
  const { savedSearches, fetching: savedSearchesFetching } = useSavedSearches();
  const userZip = useAppSelector(selectUserZip);
  const [userLocation, setUserLocation] = useState<GeolocationPosition>();
  const [userLocationFetching, setUserLocationFetching] = useState<boolean>(true);
  const [newListingsUrl, setNewListingsUrl] = useState<string>();
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const config = getConfigFromStore();
  const baseUrl = config?.links['home-search'];
  const host = window.location.host;
  const newListingsPublicKey = content.homeSearch.newListingsPublicKey;

  const newListingsUrlBase =
    `${baseUrl}/property-widget?public_key=${newListingsPublicKey}&parent_site_hostname=${host}&sso=true`;

  useEffect(() => {
    const success = (position: GeolocationPosition) => {
      setUserLocation(position);
      setUserLocationFetching(false);
    };

    const error = (error: GeolocationPositionError) => {
      log({ level: 'error', message: `Error getting user location ${error}` });
      setUserLocationFetching(false);
    };

    // get user current location
    navigator.geolocation.getCurrentPosition(success, error, { timeout: 10000 });
  }, []);

  useEffect(() => {
    // #1 precedent - user zip trumps all
    if (userZip) {
      const newUrl = `${newListingsUrlBase}&zipcode=${userZip}`;
      setNewListingsUrl(newUrl);
      return;
    }

    // #2 precedent - user geo location
    if (userLocation) {
      const userPoint = { latitude: userLocation.coords.latitude, longitude: userLocation.coords.longitude };
      // 8000 meters is about 5 miles
      const point1 = computeDestinationPoint(userPoint, 8000, 225);
      const point2 = computeDestinationPoint(userPoint, 8000, 45);
      const newUrl = `${newListingsUrlBase}&loc=${point1.latitude},${point1.longitude},${point2.latitude},${point2.longitude}`;
      setNewListingsUrl(newUrl);
      return;
    }

    // #3 precedent - saved searches
    if (savedSearches && savedSearches.length > 0) {
      const savedSearch = savedSearches[0];
      const newUrl = `${newListingsUrlBase}&city_state=${savedSearch.savedSearchState.searchNameIdentifier}`; 
      setNewListingsUrl(newUrl);
      return;
    }

    // #4 if everything else fails - default to Chicago
    if (!savedSearchesFetching && !userLocationFetching) {
      const newUrl = `${newListingsUrlBase}&city_state=Chicago,IL`; 
      setNewListingsUrl(newUrl);
      return;
    }
  }, [userZip, userLocation, savedSearches, savedSearchesFetching, newListingsUrlBase, userLocationFetching]);

  useEffect(() => {
    if (iframeLoaded && newListingsUrl && onReady) {
      onReady();
    }
  }, [iframeLoaded, newListingsUrl, onReady]);

  const onLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <Embed className={cn('mt-8 w-full overflow-hidden', { hidden })} style={{ height: '500px' }} title='New listings' src={newListingsUrl || ''} onLoad={onLoad} />
  );
};

export default NewListings;
