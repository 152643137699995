import { useEffect, useState } from 'react';
import { useAppDispatch } from './hooks';
import { updateGlobalOnline } from '../features/app/appSlice';

export const useNetwork = () => {
  const [online, setOnline] = useState(navigator.onLine);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      dispatch(updateGlobalOnline(true));
    };

    const handleOffline = () => {
      setOnline(false);
      dispatch(updateGlobalOnline(false));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  return online;
};

