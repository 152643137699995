import { SwiperItem } from '../../../features/tasks/types';
import { swiperPayment } from '../../../utils/grLinks';
import { content } from '../../../features/tenant/tenant';
import DataItem from '../../ui/datalist/dataItem/DataItem';
import Datalist from '../../ui/datalist/Datalist';
import Embed from '../../ui/embed/Embed';
import { PaymentModalProps } from './IPayment';
import './PaymentModal.scss';

const getDataItems = (items: SwiperItem[]) =>
  items.map((item: SwiperItem, index: number) => <DataItem key={index} label={item.desc} value={`$${item.amount}`}></DataItem>);

const PaymentModal = ({ swiperId, showSummary, taskTitle, items, totalAmount, language }: PaymentModalProps) => {
  return (
    <>
      {showSummary ? <div className='dlist-headline'>Fee summary</div> : null}
      {showSummary && items && getDataItems(items).length > 0 ? (
        <Datalist inline totalAmount={`$${totalAmount}`} totalTitle='Total fees'>
          {getDataItems(items)}
        </Datalist>
      ) : null}
      {showSummary ? (
        <p>
          By submitting my credit card information below, I authorize {content.company} to charge me{' '}
          <span className='font-bold'>{`$${totalAmount}`}</span>.
        </p>
      ) : null}
      <div className='payment-modal__container text-sm lg:text-base'>
        <div className='payment-summary my-4'></div>
        <Embed className='payment-modal__iframe overflow-hidden' title={taskTitle} src={swiperPayment(swiperId, language)} />
      </div>
    </>
  );
};

export default PaymentModal;
