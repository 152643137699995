import { useState } from 'react';
import Slide from '../../../components/animations/Slide';
import Button from '../../../components/ui/button/Button';
import FadeSlide from '../../../components/animations/FadeSlide';
import { iDirectionTypes } from '../../../components/animations/ISlide';
import SlideOut from '../../../components/animations/SlideOut';

 
const Block = () => {
  return (
    <div className='w-full h-12' style={{ backgroundColor: 'red' }} />
  );
};

const SlideStyleguide = ({ direction }: { direction: iDirectionTypes }) => {
  const [on, setOn] = useState(true);
  return (
    <>
      <Button
        onClick={() => setOn(!on)}
        className='mb-4'
        buttonType='tertiary'
        text={`Slide ${direction}`}
      />
      <Slide
        on={on}
        duration={500}
        directionFrom={direction}
      >
        <Block />
      </Slide>
    </>
  );
};

const FadeSlideStyleguide = ({ direction }: { direction: iDirectionTypes }) => {
  const [on, setOn] = useState(true);
  return (
    <>
      <Button
        onClick={() => setOn(!on)}
        className='mb-4'
        buttonType='tertiary'
        text={`Fade ${direction}`}
      />
      <FadeSlide
        on={on}
        duration={500}
        directionFrom={direction}
      >
        <Block />
      </FadeSlide>
    </>
  );
};

const SlideoutStyleguide = () => {
  const [on, setOn] = useState(true);
  return (
    <>
      <Button
        onClick={() => setOn(!on)}
        className='mb-4'
        buttonType='tertiary'
        text={`Slide out`}
      />
      <SlideOut
        on={on}
        duration={500}
      >
        <Block />
      </SlideOut>
    </>
  );
};

const AnimationStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Animation</div>
      <SlideStyleguide direction='up' />
      <div className='my-12' />
      <SlideStyleguide direction='down' />
      <div className='my-12' />
      <SlideStyleguide direction='left' />
      <div className='my-12' />
      <SlideStyleguide direction='right' />
      <div className='my-12' />
      <FadeSlideStyleguide direction='up' />
      <div className='my-12' />
      <FadeSlideStyleguide direction='down' />
      <div className='my-12' />
      <FadeSlideStyleguide direction='left' />
      <div className='my-12' />
      <FadeSlideStyleguide direction='right' />
      <div className='my-12' />
      <SlideoutStyleguide />
    </>
  );
};

export default AnimationStyleguide;
