import { ConfigurationNote, NavigationSections } from '../shared';
import AlertsStyleguide from './AlertsStyleguide';
import Badges from './Badges';
import AccordionStyleguide from './AccordionStyleguide';
import Controls from './Controls';
import InsightsPanel from './InsightsPanel';
import TooltipStyleguide from './TooltipStyleguide';
import Inputs from './Inputs';
import Loaders from './Loaders';
import ProgressIndicators from './ProgressIndicators';
import ModalsStyleguide from './ModalsStyleguide';
import DataListStyleguide from './DataListStyleguide';
import AddressStyleguide from './AddressStyleguide';
import AvatarStyleguide from './AvatarStyleguide';
import Button from '../../../components/ui/button/Button';
import { startConfetti } from '../../../utils/confetti';
import Card from '../../../components/ui/card/Card';
import Disclaimer from '../../../components/ui/disclaimer/Disclaimer';
import CircleStyleguide from './CircleStyleguide';
import DropdownStyleguide from './DropdownStyleguide';
import StepperStyleguide from './StepperStyleguide';
import ActionBannerListStyleguide from './ActionBannerListStyleguide';
import NavigationStyleguide from './NavigationStyleguide';
import SupportStyleguide from './SupportStyleguide';
import SkeletonStyleguide from './SkeletonStyleguide';
import SortableTableStyleguide from './SortableTableStyleguide';
import LoanOfficerCardStyleguide from './LoanOfficerCardStyleguide';
import InsuranceCalloutStyleguide from './InsuranceCalloutStyleguide';
import RateAppAdStyleguide from './RateAppAdStyleguide';
import TimeFormatsStyleguide from './TimeFormatsStyleguide';
import TasklistStyleguide from './TasklistStyleguide';
import LoanCardsStyleguide from './LoanCardsStyleguide';
import CarouselStyleguide from './CarouselStyleguide';
import SavedHomesStyleguide from './SavedHomesStyleguide';
import AnimationStyleguide from './AnimationStyleguide';

const navSections = [
  'Navigation Menu',
  'Inputs',
  'Controls',
  'Modal',
  'Badges',
  'Loaders',
  'Skeletons',
  'Progress',
  'Accordion',
  'Insights panel',
  'Tool tip',
  'Alerts',
  'DataList',
  'Address',
  'Avatar',
  'Confetti',
  'Card',
  'Next steps',
  'Circle',
  'Dropdown',
  'Disclaimer',
  'Stepper',
  'Rate App Ad',
  'Insurance Callout',
  'Loan Officer Card',
  'Support',
  'Table',
  'Time Formats',
  'Tasklist',
  'Loan cards',
  'Carousel',
  'SavedHomes',
  'Animation',
];

const Components = () => {
  return (
    <div className='components'>
      <div className='flex justify-center flex-wrap mb-12'>
        <NavigationSections navSections={navSections} />
      </div>

      <div id='Navigation Menu'>
        {/* here for now */}
        <NavigationStyleguide />
      </div>

      <div id='Inputs'>
        <Inputs />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Controls'>
        <Controls />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Modal'>
        <ModalsStyleguide />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Badges'>
        <Badges />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Loaders'>
        <Loaders />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Skeletons'>
        <div className='mt-6 mb-12'>
          <SkeletonStyleguide />
        </div>
      </div>

      <div id='Progress'>
        <ProgressIndicators />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Accordion'>
        <AccordionStyleguide />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Insights panel'>
        <InsightsPanel />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Tool tip'>
        <TooltipStyleguide />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='Alerts'>
        <AlertsStyleguide />
        <div className='mt-6 mb-12'>
          <ConfigurationNote message='None. Styles and nested components will change based on atoms.' />
        </div>
      </div>

      <div id='DataList'>
        <div className='mt-6 mb-12'>
          <DataListStyleguide />
        </div>
      </div>

      <div id='Address'>
        <div className='mt-6 mb-12'>
          <AddressStyleguide />
        </div>
      </div>

      <div id='Avatar'>
        <div className='mt-6 mb-12'>
          <AvatarStyleguide />
        </div>
      </div>

      <div id='Confetti'>
        <div className='mt-6 mb-12'>
          <div className='header-medium mb-8'>Confetti</div>
          <Button
            text='Let it rain'
            title='Large Button'
            buttonType='primary'
            size='large'
            onClick={() => {
              startConfetti(2000);
            }}
          />
        </div>
      </div>

      <div id='Card'>
        <div className='mt-6 mb-12'>
          <div className='header-medium mb-8'>Card</div>
          <Card>
            <h1>Hello, I'm a card.</h1>
          </Card>
        </div>
      </div>

      <div id='Next steps'>
        <div className='mt-6 mb-12'>
          <ActionBannerListStyleguide />
        </div>
      </div>

      <div id='Circle'>
        <div className='mt-6 mb-12'>
          <CircleStyleguide />
        </div>
      </div>

      <div id='Dropdown'>
        <div className='mt-6 mb-12'>
          <DropdownStyleguide />
        </div>
      </div>

      <div id='Disclaimer'>
        <div className='mt-6 mb-12' style={{ maxWidth: '400px' }}>
          <div className='header-medium mb-8'>Disclaimer</div>
          <Disclaimer
            text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed
        iaculis turpis, at ornare diam. Nunc tincidunt, sem eu bibendum
        fringilla, felis erat congue nisi, ut scelerisque ipsum sem at nisl.
        Nunc velit arcu, posuere eget turpis sed, rutrum pharetra sapien. Donec
        vehicula bibendum lorem, eget rhoncus dui lobortis a. Vestibulum auctor
        laoreet lorem. Cras consequat urna aliquet sem viverra, id maximus lacus
        consectetur. Mauris mattis quam id felis dapibus, non ultrices dui
        ultrices. Suspendisse potenti. In hac habitasse platea dictumst. Ut
        lorem orci, iaculis id lacus eget, cursus placerat lorem. Duis accumsan
        tortor id sapien viverra, tincidunt posuere lorem interdum. Nam id
        mauris dignissim, porttitor orci at, suscipit orci. Ut metus dolor,
        suscipit eu imperdiet molestie, congue in ligula. Nullam accumsan erat
        commodo quam porta, eu pharetra magna laoreet. Nam id dolor justo.
        Maecenas rutrum turpis in augue mollis faucibus. Sed dignissim dui ut
        lectus vehicula malesuada. Proin dictum eros eu ligula imperdiet
        imperdiet. Orci varius natoque penatibus et magnis dis parturient
        montes, nascetur ridiculus mus.'
          />
        </div>
      </div>

      <div id='Stepper'>
        <div className='mt-6 mb-12'>
          <StepperStyleguide />
        </div>
      </div>

      <div id='Rate App Ad'>
        <div className='mt-6 mb-12'>
          <RateAppAdStyleguide />
        </div>
      </div>

      <div id='Insurance Callout'>
        <div className='mt-6 mb-12'>
          <InsuranceCalloutStyleguide />
        </div>
      </div>

      <div id='Loan Officer Card'>
        <div className='mt-6 mb-12'>
          <LoanOfficerCardStyleguide />
        </div>
      </div>

      <div id='Support'>
        <div className='mt-6 mb-12'>
          <SupportStyleguide />
        </div>
      </div>

      <div id='Table'>
        <div className='mt-6 mb-12'>
          <SortableTableStyleguide />
        </div>
      </div>

      <div id='Time Formats'>
        <div className='mt-6 mb-12'>
          <TimeFormatsStyleguide />
        </div>
      </div>

      <div id='Tasklist'>
        <div className='mt-6 mb-12'>
          <TasklistStyleguide />
        </div>
      </div>

      <div id='Loan cards'>
        <div className='mt-6 mb-12'>
          <LoanCardsStyleguide />
        </div>
      </div>

      <div id='Carousel'>
        <div className='mt-6 mb-12'>
          <CarouselStyleguide />
        </div>
      </div>

      <div id='SavedHomes'>
        <div className='mt-6 mb-12'>
          <SavedHomesStyleguide />
        </div>
      </div>

      <div id='Animation'>
        <div className='mt-6 mb-12'>
          <AnimationStyleguide />
        </div>
      </div>

    </div>
  );
};

export default Components;
