import { CSSProperties } from 'react';
import Animation from './Animation';
import { SlideProps, iDirectionTypes } from './ISlide';

export const getTranslate = (direction: iDirectionTypes, distance: string) => {
  const map = {
    left: `translate3d(-${distance}, 0, 0)`,
    right: `translate3d(${distance}, 0, 0)`,
    up: `translate3d(0, -${distance}, 0)`,
    down: `translate3d(0, ${distance}, 0)`,
  } as Record<iDirectionTypes, string>;
  return map[direction];
};

export const getDefaultStyles = (duration: number, direction: iDirectionTypes, distance: string): CSSProperties => ({
  transition: `transform ${duration}ms ease-in-out`,
  transform: getTranslate(direction, distance),
});

export const getTransitionStyles = (direction: iDirectionTypes, distance: string): Record<string, CSSProperties> => ({
  entering: { transform: 'translate3d(0, 0, 0)' },
  entered: { transform: 'translate3d(0, 0, 0)' },
  exiting: { transform: getTranslate(direction, distance) },
  exited: { transform: getTranslate(direction, distance) },
});

const Slide = ({ on, duration = 300, directionFrom = 'up', distance = '100%', children, className }: SlideProps) => {
  return (
    <Animation
      on={on}
      duration={duration}
      defaultStyle={getDefaultStyles(duration, directionFrom, distance)}
      transitionStyles={getTransitionStyles(directionFrom, distance)}
      className={className}
    >
      {children}
    </Animation>
  );
};

export default Slide;
