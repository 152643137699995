import { getConfigFromStore } from '../features/config/getConfigFromStore';
import { TenantExperienceLinks, TenantInsuranceLinks, TenantLinks, TenantLinksConfig } from '../features/config/types';
import { encodeParam } from './url';

// Return whether url is external
export const isExternalUrl = (url: string) => url.charAt(0) !== '/' && new URL(url).origin !== window.location.origin;

export const buildQueryParams = (params: Record<string, string | number | boolean | null | undefined>): string | null => {
  // values in params object must be truthy, passing false is invalid
  const query =
    params &&
    Object.entries(params)
      .filter(([_, v]) => v)
      .map(([key, value]) => `${encodeParam(key)}=${encodeParam(String(value))}`)
      .join('&');
  return query && `?${query}`;
};

export const builder = (subdomain: TenantLinks | 'app', path?: string | null): string => {
  const config = getConfigFromStore();
  // cast needed because 'app' is not a key of TenantLinksConfig
  const host = config?.links[subdomain as keyof TenantLinksConfig];
  return !!host ? `${host}/${path ? path : ''}` : `https://${subdomain}.${config?.tenant.host}/${path ? path : ''}`;
};

// Return a url for Okta login page
export const oktaLogin = (): string => builder('login');

// Return a url for dmx application given loan-guid
export const dmxApplication = (
  params?:
    | { 'emp-id'?: number; adtrk?: string; langPref?: string }
    | { 'gr-loan-guid'?: string; adtrk?: string; langPref?: string },
): string => {
  const encodedQueryParams = encodeURIComponent(`/${params ? buildQueryParams(params) : ''}`);
  return builder('dmx', `api/login?return-to=${encodedQueryParams}`);
};

// Return a url for ALP HELOC application given loan-guid
export const alpHELOCApplication = (encompassLoanId: string): string => {
  return builder('alp', `heloc/review/?loanId=${encompassLoanId}`);
};

// Return a url for ALP CES application given loan-guid
export const alpCESApplication = (encompassLoanId: string): string => {
  return builder('alp', `heloc/lite/?loanId=${encompassLoanId}`);
};

// Return a url for ALP auto DM application given loan-guid
export const autodmApplication = (grLoanId: string): string => {
  return builder('autodm', `confirmIdentity?loanId=${grLoanId}`);
};

export const rateAlertEdit = (invitationNumber: string): string => {
  return builder('rate-alert', `?invitation=${invitationNumber}`);
};

// Return a url for swiper payment given payment id
export const swiperPayment = (paymentId: string, language: string | null): string =>
  builder('swiper', `v1/payment?id=${paymentId}&app=myaccount&embed=true${language ? '&lang=' + language : ''}`);

// Return a url for simon given optional path
export const simon = (path?: string): string => builder('simon', path);

export const talkuments = (path?: string): string => builder('talkuments', path);

export const insurance = (type: TenantInsuranceLinks) => {
  const config = getConfigFromStore();
  const insuranceConfig = config?.links.insurance;
  if (!!insuranceConfig && !!insuranceConfig[type]) {
    const baseURL = insuranceConfig[type];
    switch (type) {
      case 'life':
        // add Adobe Analytics tags
        return `${baseURL}?utm_source=myaccount&utm_medium=banner&utm_campaign=insurancecenter#lifeInsuranceEmbed`;
      case 'pet':
        // add GA cross site tags and Adobe Analytics tags
        return `${baseURL}?_ga=2.228681500.1083008943.1657571212-1774900987.1636647858&_gl=1*ynd9ej*_ga*MTc3NDkwMDk4Ny4xNjM2NjQ3ODU4*_ga_PVQ83DP3Z2*MTY1NzY1NzI2Mi42Mi4xLjE2NTc2NTcyNjYuMA..&utm_source=myaccount&utm_medium=banner&utm_campaign=insurancecenter`;
      case 'bundle':
        return baseURL;
      case 'auto':
      case 'home':
        // add Adobe Analytics tags
        return `${baseURL}?utm_source=myaccount&utm_medium=banner&utm_campaign=insurancecenter`;
    }
  }
};

export const expLink = (experienceParam: string | undefined) => {
  // TenantExperienceLinks is any string
  const experience = experienceParam as TenantExperienceLinks;
  if (!!experience) {
    const config = getConfigFromStore();
    const experienceConfig = config?.links.experience;
    if (!!experienceConfig && !!experienceConfig[experience]) {
      return experienceConfig[experience];
    }
  }
  return null;
};

export const mainSite = (url: string, { loid, adtrk, icid }: { loid?: number; adtrk?: string; icid?: string } = {}) => {
  const params = { ...(loid && { loid }), ...(adtrk && { adtrk }), ...(icid && { icid }) };
  return Object.keys(params).length ? `${url}${buildQueryParams(params)}` : url;
};
