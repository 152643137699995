import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { selectProcessingHELOCLoanIds, selectFundedHELOCLoanIds, selectHELOCLoans } from '../../features/helocLoans/helocLoansSlice';
import {
  selectProcessingLoanGuids,
  selectLoansByGuidEntity,
  selectProcessingCESLoanGuids,
  selectProcessingAlpHELOCLoanGuids,
  selectFundedLoanGuids,
  selectFundedCESLoanGuids,
  selectFundedAlpHELOCLoanGuids
} from '../../features/loans/loansSlice';
import { selectProcessingPersonalLoanIds, selectFundedPersonalLoanIds, selectPersonalLoans } from '../../features/personalLoans/personalLoansSlice';
import { useAppSelector } from '../../hooks/hooks';
import { DashboardAlpHELOCLoanCard } from '../../components/loan/loanCard/DashboardAlpHELOCLoanCard';
import { DashboardCESLoanCard } from '../../components/loan/loanCard/DashboardCESLoanCard';
import { DashboardHELOCLoanCard } from '../../components/loan/loanCard/DashboardHELOCLoanCard';
import { DashboardHomeLoanCard } from '../../components/loan/loanCard/DashboardHomeLoanCard';
import { DashboardNoLoansCard } from '../../components/loan/loanCard/DashboardLoanCards';
import { DashboardPersonalLoanCard } from '../../components/loan/loanCard/DashboardPersonalLoanCard';
import ApplyLoanButton from '../../components/ui/button/ApplyLoanButton';
import { sendEvent } from '../../features/analytics/sendEvent';
import { log } from '../../utils/logger';
import { PATHS } from '../../components/routes/paths';
import Button from '../../components/ui/button/Button';

const selectProcessingLoans = createSelector(
  selectProcessingLoanGuids,
  selectProcessingCESLoanGuids,
  selectProcessingAlpHELOCLoanGuids,
  selectProcessingPersonalLoanIds,
  selectProcessingHELOCLoanIds,
  (
    processingHomeLoanGuids,
    processingCESLoanGuids,
    processingAlpHELOCLoanGuids,
    processingPersonalLoanGuids,
    processingHELOCLoanGuids,
  ) => {
    return {
      home: processingHomeLoanGuids,
      ces: processingCESLoanGuids,
      alpHeloc: processingAlpHELOCLoanGuids,
      personal: processingPersonalLoanGuids,
      heloc: processingHELOCLoanGuids,
    };
  }
);

const selectFundedDashboardLoans = createSelector(
  selectFundedLoanGuids,
  selectLoansByGuidEntity,
  selectFundedCESLoanGuids,
  selectFundedAlpHELOCLoanGuids,
  selectFundedPersonalLoanIds,
  selectPersonalLoans,
  selectFundedHELOCLoanIds,
  selectHELOCLoans,
  (
    fundedHomeLoanGuids,
    homeLoansMap,
    fundedCESLoanGuids,
    fundedAlpHELOCLoanGuids,
    fundedPersonalLoanGuids,
    personalLoansMap,
    fundedHELOCLoanGuids,
    helocLoansMap
  ) => {
    const now = dayjs();

    const dashboardFundedHomeLoans = fundedHomeLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 60;
    });

    const dashboardFundedCESLoans = fundedCESLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 60;
    });

    const dashboardFundedAlpHELOCLoans = fundedAlpHELOCLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 60;
    });

    const dashboardFundedPersonalLoans = fundedPersonalLoanGuids?.filter(guid => {
      const personalLoan = personalLoansMap[guid];
      const closingDate = personalLoan.loanDetails.contract?.fundingDate;
      return personalLoan && closingDate && now.diff(dayjs(closingDate), 'days') < 60;
    });

    const dashboardFundedHELOCLoans = fundedHELOCLoanGuids?.filter(guid => {
      const helocLoan = helocLoansMap[guid];
      const closingDate = helocLoan.loanDetails.fundingDate;
      return helocLoan && closingDate && now.diff(dayjs(closingDate), 'days') < 60;
    });

    return {
      home: dashboardFundedHomeLoans,
      ces: dashboardFundedCESLoans,
      alpHeloc: dashboardFundedAlpHELOCLoans,
      personal: dashboardFundedPersonalLoans,
      heloc: dashboardFundedHELOCLoans,
    };
  }
);

const MoreLoansButton = ({ displayFundedLoans }: { displayFundedLoans: Record<string, string[]> }) => {
  const fundedLoansGuids = useAppSelector(selectFundedLoanGuids);
  const fundedCesLoansGuids = useAppSelector(selectFundedCESLoanGuids);
  const fundedAlpHELOCLoansGuids = useAppSelector(selectFundedAlpHELOCLoanGuids);
  const fundedPersonalLoansGuids = useAppSelector(selectFundedPersonalLoanIds);
  const fundedHELOCLoansGuids = useAppSelector(selectFundedHELOCLoanIds);

  let hidingSomeLoans = false;
  if (fundedLoansGuids?.length > displayFundedLoans.home?.length) {
    hidingSomeLoans = true;
  } else if (fundedCesLoansGuids?.length > displayFundedLoans.ces?.length) {
    hidingSomeLoans = true;
  } else if (fundedAlpHELOCLoansGuids?.length > displayFundedLoans.alpHeloc?.length) {
    hidingSomeLoans = true;
  } else if (fundedPersonalLoansGuids?.length > displayFundedLoans.personal?.length) {
    hidingSomeLoans = true;
  } else if (fundedHELOCLoansGuids?.length > displayFundedLoans.heloc?.length) {
    hidingSomeLoans = true;
  }

  if (!hidingSomeLoans) return null;

  return (
    <Button
      buttonType='tertiary'
      to={PATHS.loanCenter}
      text='See funded loans'
    />
  );
};

const DashboardLoansList = () => {
  const processingLoans = useAppSelector(selectProcessingLoans);
  const fundedLoans = useAppSelector(selectFundedDashboardLoans);

  // Mortgage loans
  const processinghomeLoanIds = processingLoans.home;
  const fundedhomeLoanIds = fundedLoans.home;

  // CES loans
  const processingCESLoanIds = processingLoans.ces;
  const fundedCESLoanIds = fundedLoans.ces;

  // Personal loans
  const processingPersonalLoanIds = processingLoans.personal;
  const fundedPersonalLoanIds = fundedLoans.personal

  // ALP HELOC
  const processingAlpHELOCLoanIds = processingLoans.alpHeloc;
  const fundedAlpHELOCLoanIds = fundedLoans.alpHeloc;

  // HELOC loans
  const processingHELOCLoanIds = processingLoans.heloc;
  const fundedHELOCLoanIds = fundedLoans.heloc;

  const hasProcessingLoans =
    !!processinghomeLoanIds.length ||
    !!processingCESLoanIds.length ||
    !!processingHELOCLoanIds.length ||
    !!processingPersonalLoanIds.length ||
    !!processingAlpHELOCLoanIds.length
    ;

  const hasFundedLoans =
    !!fundedhomeLoanIds?.length ||
    !!fundedCESLoanIds?.length ||
    !!fundedHELOCLoanIds.length ||
    !!fundedPersonalLoanIds.length ||
    !!fundedAlpHELOCLoanIds?.length
    ;

  if (!hasProcessingLoans && !hasFundedLoans) {
    return (
      <>
        <DashboardNoLoansCard />
        <MoreLoansButton displayFundedLoans={fundedLoans} />
      </>
    );
  }

  const onApplyClick = () => {
    sendEvent('startAnApplication: dashboard loan cards');
    log({ level: 'info', message: 'startAnApplication: dashboard loan cards' });
  };

  return (
    <div className='flex flex-col'>
      <ul className='space-y-8'>
        {processinghomeLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardHomeLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingCESLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardCESLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingAlpHELOCLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardAlpHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingHELOCLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingPersonalLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardPersonalLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedhomeLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardHomeLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedCESLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardCESLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedAlpHELOCLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardAlpHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedHELOCLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedPersonalLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardPersonalLoanCard loanGuid={id} />
            </li>
          );
        })}
      </ul>
      <ApplyLoanButton className='mt-2' text='Start a new loan' onClick={onApplyClick} buttonType='tertiary' />
      <MoreLoansButton displayFundedLoans={fundedLoans} />
    </div>
  );
};

export default DashboardLoansList;
