import { AnimationBaseProps } from './IAnimation';
import Animation from './Animation';
import { CSSProperties } from 'react';
import AnimateHeight from 'react-animate-height';

export type SlideOutProps = AnimationBaseProps;

export const getDefaultStyles = (duration: number): CSSProperties => ({
  transition: `transform ${duration}ms ease-in-out`,
});

export const getTransitionStyles = (): Record<string, CSSProperties> => ({
  entering: { transform: 'scaleY(1)' },
  entered: { transform: 'scaleY(1)' },
  exiting: { transform: 'scaleY(0)' },
  exited: { transform: 'scaleY(0)' },
});

const SlideOut = ({ on, duration = 300, children, className }: SlideOutProps) => {
  return (
    <Animation
      on={on}
      duration={duration}
      defaultStyle={getDefaultStyles(duration)}
      transitionStyles={getTransitionStyles()}
      className={className}
    >
      <AnimateHeight height={on ? 'auto' : 0} duration={duration}>
        {children}
      </AnimateHeight>
    </Animation>
  );
};

export default SlideOut;
