import { useState } from 'react';
import { PreapprovedCongratsModalContent } from '../../../components/loan/modals/preapprovals/PreapprovalsCongratsModal';
import Modal from '../../../components/ui/modal/Modal';
import ModalHeader from '../../../components/ui/modal/ModalHeader';
import FileUpload from '../../../components/ui/upload/FileUpload';
import { content } from '../../../features/tenant/tenant';
import { startConfetti } from '../../../utils/confetti';
import ModalFooter from '../../../components/ui/modal/ModalFooter';
import { WelcomeModalContent } from '../../../components/loan/modals/welcome/WelcomeModal';
import HomeValueEditModal from '../../../components/homeValue/homeValueEditModal/HomeValueEditModal';
import InsuranceModalStyleguide from './InsuranceModalStyleguide';
import ModalLoading from '../../../components/ui/modal/ModalLoading';
import { ModalConsentSuccess, ModalConsentDeclineConfirmation } from '../../../components/tasks/consent/ConsentModal';
import PaymentModal from '../../../components/tasks/payment/PaymentModal';
import { WaitingContent } from '../../../components/loan/waitingForTasks/WaitingForTasks';
import { ReactComponent as HouseGraphic } from '../../../images/house-sprite.svg';
import { InactivityTimeoutModalContent } from '../../../components/inactivityTimeout/InactivityTimeout';
import ErrorModalContent from '../../../components/ui/modal/ErrorModalContent';
import useLoanSupport from '../../../hooks/useLoanSupport';
import { MilestoneModalContent } from '../../../components/loan/modals/milestone/MilestoneModal';
import { LoanMilestones } from '../../../features/loans/types';
import mockLoanInprocess from '../../../mocks/loanInprocess';
import { SpanishNavigationDisclaimerModalContent } from '../../../components/routes/modals/SpanishNavigationDisclaimerModal';
import LanguagePreferenceModalContent from '../../../components/tasks/languagePreference/LanguagePreferenceModal';
import { useAppSelector } from '../../../hooks/hooks';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { LoanOfficerCardModalContent } from '../../../components/loan/modals/support/LoanOfficerCardModalContent';
import mockLoanOfficer from '../../../mocks/teamLo';
import { noop } from 'rxjs';
import useRecursiveTimeout from '../../../hooks/useRecursiveTimeout';
import Button from '../../../components/ui/button/Button';
import ModalDisclaimerContent from '../../../components/ui/modal/ModalDisclaimerContent';
import { EligibilityModalContent } from '../../../components/loan/modals/eligibility/Eligibility';
import { EligibleModalContent, useEligibilityModalContent } from '../../../hooks/useEligibilityModalContent';
import { CustomerCareCardModalContent } from '../../../components/loan/modals/support/CustomerCareCardModalContent';
import AgentFormModalContent from '../../../components/tasks/agent/AgentFormModal';
import { taskAgentConsent } from '../../../mocks/tasks';
import { FastTrackApprovedModalContent } from '../../../components/loan/modals/fastTrack/FastTrackApprovedModal';
import { getFundedAlpHELOCLoan } from '../../../mocks/alpHelocLoanInprocess';
import Embed from '../../../components/ui/embed/Embed';
import '../../financialSolutions/FinancialSolution.scss';

const ModalExampleContent = ({ counter, closeModal }: { counter?: number; closeModal?: () => void }) => {
  const [count, setCount] = useState<number>(1);

  useRecursiveTimeout(
    () => {
      return new Promise(resolve => {
        setCount(count + 1);
        resolve(true);
      });
    },
    1000,
    false,
    !counter,
  );

  return (
    <div>
      <div>Counter: {counter || count}</div>
      {closeModal && (
        <div>
          <button onClick={() => closeModal?.()}>Close Modal</button>
        </div>
      )}
    </div>
  );
};

const ModalExample1 = () => {
  return (
    <Modal
      onOpen={() => {
        console.log('onOpen');
      }}
      onOpened={() => {
        console.log('onOpened');
      }}
      onClose={() => {
        console.log('onClose');
      }}
      onClosed={() => {
        console.log('onClosed');
      }}
      buttonProps={{
        text: 'Open Modal 1',
        buttonType: 'primary',
      }}
      title='Modal Title'
      contentLabel='Modal Content Label'
    >
      <ModalHeader title='Modal Title' subtitle='Modal Subtitle'>
        Basic Modal
      </ModalHeader>
      <ModalExampleContent />
      <ModalFooter>Modal Footer</ModalFooter>
    </Modal>
  );
};

const ModalExample2 = () => {
  return (
    <Modal
      onOpen={() => {
        console.log('onOpen');
      }}
      onOpened={() => {
        console.log('onOpened');
      }}
      onClose={() => {
        console.log('onClose');
      }}
      onClosed={() => {
        console.log('onClosed');
      }}
      buttonProps={{
        text: 'Open Modal 2',
        buttonType: 'primary',
      }}
      title='Modal Title'
      contentLabel='Modal Content Label'
      render={(closeModal) => (
        <>
          <ModalHeader title='Modal Title' subtitle='Modal Subtitle'>
            Basic Modal, can close from content
          </ModalHeader>
          <ModalExampleContent closeModal={closeModal} />
          <ModalFooter>Modal Footer</ModalFooter>
        </>
      )}
    ></Modal>
  );
};

const ModalExample3 = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);

  useRecursiveTimeout(
    () => {
      return new Promise(resolve => {
        setCount(count + 1);
        resolve(true);
      });
    },
    1000,
    false,
    true,
  );

  return (
    <>
      <Button buttonType='primary' onClick={() => setIsOpen(true)}>
        Open Modal 3
      </Button>
      <Modal
        open={isOpen}
        onOpen={() => {
          console.log('onOpen');
        }}
        onOpened={() => {
          console.log('onOpened');
        }}
        onClose={() => {
          console.log('onClose');
        }}
        onClosed={() => {
          console.log('onClosed');
        }}
        onRequestClose={() => setIsOpen(false)}
        title='Modal Title'
        contentLabel='Modal Content Label'
      >
        <ModalHeader title='Modal Title' subtitle='Modal Subtitle'>
          Modal, parent counter
        </ModalHeader>
        <ModalExampleContent closeModal={() => setIsOpen(false)} counter={count} />
        <ModalFooter>Modal Footer</ModalFooter>
      </Modal>
    </>
  );
};

const ModalsStyleguide = () => {
  const support = useLoanSupport('fake-loan-guid');
  const { supportEmail, supportPhone, supportPhoneOption } = content;

  const clearToCloseLoan1 = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLEAR_TO_CLOSE };
  const clearToCloseLoan2 = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLEAR_TO_CLOSE, 'respa-to-ctc': '6 hours'};
  const clearToCloseLoan3 = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLEAR_TO_CLOSE, 'respa-to-ctc': '10 days'};
  const closedLoan = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLOSED };
  const clearToCloseLoanNoPreapproval = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLEAR_TO_CLOSE, 'show-preapproval-letters?': false };
  const clearToCloseLoanRefinance = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLEAR_TO_CLOSE, 'show-preapproval-letters?': false, 'refinance?': true };
  const closedLoanNoPreapproval = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLOSED, 'show-preapproval-letters?': false };
  const closedLoanRefinance = { ...mockLoanInprocess, 'loan-milestone': LoanMilestones.CLOSED, 'show-preapproval-letters?': false, 'refinance?': true };
  const fundedAlpHelocLoan = getFundedAlpHELOCLoan();
  const appLanguage = useAppSelector(selectAppLanguage);
  const consentContent = {
    subtitle: "You'll reap the benefits of a faster, more secure mortgage experience by saying yes!",
    title: 'Would you like to be able to sign your documents online throughout the process?',
    disclaimer: mockConsentDisclaimer,
  };
  const eligibilityBizHoursApproved = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'approved' }, true) as EligibleModalContent;
  const eligibilityOffBizHoursApproved = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'approved' }, false) as EligibleModalContent;
  const eligibilityBizHoursEligible = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'eligible' }, true) as EligibleModalContent;
  const eligibilityOffBizHoursEligible = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'eligible' }, false) as EligibleModalContent;
  const eligibilityBizHoursPrequal = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'prequal' }, true) as EligibleModalContent;
  const eligibilityOffBizHoursPrequal = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'prequal' }, false) as EligibleModalContent;
  const eligibilityBizHoursNotEligible = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'not-eligible' }, true) as EligibleModalContent;
  const eligibilityOffBizHoursNotEligible = useEligibilityModalContent({ ...mockLoanInprocess, 'same-day-status': 'not-eligible' }, false) as EligibleModalContent;

  return (
    <>
      <div className='modals-styleguide'>
        <div className='header-medium'>Modal</div>
        <div className='body-medium italic mb-2'>Adjust browser for proper responsive sizing</div>
        <div>
          <ul className='mb-8'>
            <li className='mb-4'>
              <ModalExample1 />
            </li>
            <li className='mb-4'>
              <ModalExample2 />
            </li>
            <li className='mb-4'>
              <ModalExample3 />
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Loading Modal',
                  buttonType: 'primary',
                }}
                title='Modal Loading Title'
                contentLabel='Modal Loading Content Label'
              >
                <ModalLoading />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Consent Modal',
                  buttonType: 'primary',
                }}
                title='Modal Consent'
                contentLabel='Modal Consent'
              >
                <ModalDisclaimerContent
                  title={consentContent.title}
                  subtitle={consentContent.subtitle}
                  disclaimer={consentContent.disclaimer}
                  onAccept={() => {
                    console.log('Accept clicked');
                  }}
                  onDecline={() => {
                    console.log('Decline clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Consent Decline Confirmation Modal',
                  buttonType: 'primary',
                }}
                title='Modal Consent Decline Confirmation Title'
                contentLabel='Modal Consent Decline Confirmation Content Label'
              >
                <ModalConsentDeclineConfirmation
                  onCancel={() => {
                    console.log('Cancel clicked');
                  }}
                  onContinue={() => {
                    console.log('Continue clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Consent Success (agreed) Modal',
                  buttonType: 'primary',
                }}
                title='Modal Consent Success Title'
                contentLabel='Modal Consent Success Content Label'
              >
                <ModalConsentSuccess
                  agree={true}
                  onDone={() => {
                    console.log('Done clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Consent Success (disagreed) Modal',
                  buttonType: 'primary',
                }}
                title='Modal Consent Success Title'
                contentLabel='Modal Consent Success Content Label'
              >
                <ModalConsentSuccess
                  agree={false}
                  onDone={() => {
                    console.log('Done clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Consent Failed Modal',
                  buttonType: 'primary',
                }}
                title='Modal Consent Failed Title'
                contentLabel='Modal Consent Failed Content Label'
              >
                <ErrorModalContent
                  title='We were unable to update your consent'
                  location='your tasklist'
                  support={support}
                  onDone={() => {
                    console.log('Done clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Language Preference Modal',
                  buttonType: 'primary',
                }}
                title='Language Preference Title'
                contentLabel='Modal Language Preference Content Label'
              >
                <LanguagePreferenceModalContent
                  loanGuid='12345'
                  taskId='54321'
                  selectedLanguage='es'
                  appLanguage={null}
                  onClick={(language: string) => {
                    console.log(`Language selected: ${language}`);
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Payment Modal',
                  buttonType: 'primary',
                }}
                title='Modal Payment Title'
                contentLabel='Modal Payment Content Label'
              >
                <PaymentModal swiperId='fa6edffb-dbbd-4e93-990e-4f2b6104db2c' showSummary={true} taskTitle={'Payment Loan Fees'} items={[{desc: 'Credit Report Fee', amount: 50}]} totalAmount={50} language={appLanguage} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Confirmation Modal',
                  buttonType: 'primary',
                }}
                title='Modal Confirmation Title'
                contentLabel='Modal Confirmation Content Label'
                render={() => (
                  <HomeValueEditModal
                    onClose={() => {
                      console.log('Close clicked');
                    }}
                    onConfirm={() => {
                      console.log('Confirm clicked');
                    }}
                  />
                )}
              />
            </li>
            <li className='mb-4'>
              <div>
                <Modal
                  buttonProps={{
                    text: 'Preapproval Modal',
                    buttonType: 'primary',
                  }}
                  title='Preapproval Modal Title'
                  contentLabel='Preapproval Modal Content Label'
                  onOpen={() => !content.milestoneModals?.disableConfetti && startConfetti(2000)}
                  onOpened={() => {
                    console.log('onOpened');
                  }}
                  onClose={() => {
                    console.log('onClose');
                  }}
                  onClosed={() => {
                    console.log('onClosed');
                  }}
                  render={() => (
                    <PreapprovedCongratsModalContent
                      amount={450000}
                      onClose={() => {
                        console.log('Primary call to action clicked');
                      }}
                    />
                  )}
                />
              </div>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Welcome Modal',
                  buttonType: 'primary',
                }}
                title='Welcome Modal Title'
                contentLabel='Welcome Modal Content Label'
              >
                <WelcomeModalContent
                  onClose={() => {
                    console.log('Primary call to action clicked');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <InsuranceModalStyleguide />
            </li>
            <li className='mb-4'>
              <div className='hidden'>
                <HouseGraphic />
              </div>
              <Modal
                buttonProps={{
                  text: 'Waiting for Tasklist Modal',
                  buttonType: 'primary',
                }}
                contentLabel='Waiting for Tasklist'
                closeable={false}
                shouldCloseOnOverlayClick={true}
                id='modal-waiting-for-tasks'
                overlayClassName='overlay-waiting-for-tasks'
                bodyOpenClassName='active-waiting-for-tasks'
                onClose={() => {
                  console.log('Modal onClose');
                }}
                onClosed={() => {
                  console.log('Modal onClosed');
                }}
                onOpen={() => {
                  console.log('Modal onOpen');
                }}
                onOpened={() => {
                  console.log('Modal onOpened');
                }}
              >
                <WaitingContent />
              </Modal>
            </li>
            <li className='mb-4'>
              <div>
                <Modal
                  buttonProps={{
                    text: 'Upload Modal',
                    buttonType: 'primary',
                  }}
                  title='Upload Modal Title'
                  contentLabel='Upload Modal Content Label'
                  render={() => (
                    <FileUpload
                      loanGuid='12345'
                      processFile={(file: File) => {
                        return new Promise(resolve => {
                          resolve([]);
                        });
                      }}
                    />
                  )}
                />
              </div>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Inactivity timeout',
                  buttonType: 'primary',
                }}
                title='Inactivity timeout'
                contentLabel='Inactivity timeout'
              >
                <InactivityTimeoutModalContent
                  counter={10}
                  onStay={() => {
                    console.log('Stay clicked');
                  }}
                  onLogout={() => {
                    console.log('Logout clicked');
                  }}
                  onTimeout={() => {
                    console.log('Timeout');
                  }}
                  expired={false}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Inactivity timeout expired',
                  buttonType: 'primary',
                }}
                title='Inactivity timeout expired'
                contentLabel='Inactivity timeout expired'
              >
                <InactivityTimeoutModalContent
                  counter={10}
                  onStay={() => {
                    console.log('Stay clicked');
                  }}
                  onLogout={() => {
                    console.log('Logout clicked');
                  }}
                  onTimeout={() => {
                    console.log('Timeout');
                  }}
                  expired={true}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Spanish Navigation Disclaimer',
                  buttonType: 'primary',
                }}
                title='Spanish Navigation'
                contentLabel='Spanish Navigation'
              >
                <SpanishNavigationDisclaimerModalContent onCancel={() => { console.log('cancel'); }} onConfirm={() => { console.log('confirm'); }} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Clear to Close',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={clearToCloseLoan1} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Clear to Close (no pre-approval)',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={clearToCloseLoanNoPreapproval} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Clear to Close (refinance)',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={clearToCloseLoanRefinance} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Clear to Close 6 hours',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={clearToCloseLoan2} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Clear to Close 10 days',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={clearToCloseLoan3} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Closed',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={closedLoan} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Closed (no pre-approval)',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={closedLoanNoPreapproval} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Closed (refinance)',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={closedLoanRefinance} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Milestone Closed (ALP HELOC)',
                  buttonType: 'primary',
                }}
                title='Milestone Modal'
                contentLabel='Milestone Modal'
              >
                <MilestoneModalContent loan={fundedAlpHelocLoan} requestClose={noop} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Loan Officer Card',
                  buttonType: 'primary',
                }}
                title='Loan Officer Card'
                contentLabel='Loan Officer Card'
              >
                <LoanOfficerCardModalContent loanOfficer={mockLoanOfficer} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Customer Care',
                  buttonType: 'primary',
                }}
                title='Customer Care'
                contentLabel='Customer Care'
              >
                <CustomerCareCardModalContent email={supportEmail} phone={supportPhone} phoneOption={supportPhoneOption} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Approved (Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Approved (Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityBizHoursApproved.bannerImage}
                  bannerMobileImage={eligibilityBizHoursApproved.bannerMobileImage}
                  bannerText={eligibilityBizHoursApproved.bannerText}
                  bodyText={eligibilityBizHoursApproved.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Approved (Not Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Approved (Not Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityOffBizHoursApproved.bannerImage}
                  bannerMobileImage={eligibilityOffBizHoursApproved.bannerMobileImage}
                  bannerText={eligibilityOffBizHoursApproved.bannerText}
                  bodyText={eligibilityOffBizHoursApproved.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Eligible (Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Eligible (Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityBizHoursEligible.bannerImage}
                  bannerMobileImage={eligibilityBizHoursEligible.bannerMobileImage}
                  bannerText={eligibilityBizHoursEligible.bannerText}
                  bodyText={eligibilityBizHoursEligible.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Eligible (Not Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Eligible (Not Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityOffBizHoursEligible.bannerImage}
                  bannerMobileImage={eligibilityOffBizHoursEligible.bannerMobileImage}
                  bannerText={eligibilityOffBizHoursEligible.bannerText}
                  bodyText={eligibilityOffBizHoursEligible.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Prequal (Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Prequal (Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityBizHoursPrequal.bannerImage}
                  bannerMobileImage={eligibilityBizHoursPrequal.bannerMobileImage}
                  bannerText={eligibilityBizHoursPrequal.bannerText}
                  bodyText={eligibilityBizHoursPrequal.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Prequal (Not Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Prequal (Not Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityOffBizHoursPrequal.bannerImage}
                  bannerMobileImage={eligibilityOffBizHoursPrequal.bannerMobileImage}
                  bannerText={eligibilityOffBizHoursPrequal.bannerText}
                  bodyText={eligibilityOffBizHoursPrequal.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Not Eligible (Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Not Eligible (Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityBizHoursNotEligible.bannerImage}
                  bannerMobileImage={eligibilityBizHoursNotEligible.bannerMobileImage}
                  bannerText={eligibilityBizHoursNotEligible.bannerText}
                  bodyText={eligibilityBizHoursNotEligible.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Eligibility Modal Not Eligible (Not Business Hour)',
                  buttonType: 'primary',
                }}
                title='Eligibility Modal Not Eligible (Not Business Hour)'
                contentLabel='Eligibility Modal'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <EligibilityModalContent
                  bannerImage={eligibilityOffBizHoursNotEligible.bannerImage}
                  bannerMobileImage={eligibilityOffBizHoursNotEligible.bannerMobileImage}
                  bannerText={eligibilityOffBizHoursNotEligible.bannerText}
                  bodyText={eligibilityOffBizHoursNotEligible.bodyText}
                  onContinue={() => console.log('continue')}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Agent Info',
                  buttonType: 'primary',
                }}
                title='Agent Info'
                contentLabel='Agent Info'
                className='h-full lg:h-fit-content'
                contentClassName='h-full'
                contentVerticalClassName='h-full lg:pb-4-important'
                contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
                closeButtonBg={false}
              >
                <AgentFormModalContent task={taskAgentConsent} onNext={() => console.log('agent info')} />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Agent Consent',
                  buttonType: 'primary',
                }}
                title='Agent Consent'
                contentLabel='Agent Consent'
              >
                <ModalDisclaimerContent
                  title='Share your loan details with your real estate agent Amy Agent?'
                  disclaimer={mockConsentDisclaimer}
                  onAccept={() => {
                    console.log('Accept clicked');
                  }}
                  onDecline={() => {
                    console.log('Decline clicked');
                  }}
                  dowloadDisclaimerLink='https://google.com'
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Fast Track Approved',
                  buttonType: 'primary',
                }}
                title='Fast Track Approved'
                contentLabel='Fast Track Approved'
              >
                <FastTrackApprovedModalContent
                  onContinue={() => {
                    console.log('On continue');
                  }}
                />
              </Modal>
            </li>
            <li className='mb-4'>
              <Modal
                buttonProps={{
                  text: 'Financial Solutions Marketing Disclaimer',
                  buttonType: 'primary',
                }}
                title='Financial Solutions Marketing Disclaimer'
                contentLabel='Financial Solutions Marketing Disclaimer'
              >
                <Embed className='w-full h-full md:h-96' title='test disclosure' src='https://www.rate.com/app/same-day-mortgage-disclaimer' />
              </Modal>
            </li>
          </ul>
        </div>
        <div className='mb-4 italics'>
          For minimal text, center align. For a large amount of text, the text should be left aligned for legibility.
        </div>
        <div className='body-small'>
          <span className='bold'>Desktop overlay specs</span>
          <ul>
            <li>Color: #000000 at 15% opacity</li>
            <li>Background Blur: 4</li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mockConsentDisclaimer = `
<h3 class="simon">ELECTRONIC RECORD AND SIGNATURE DISCLOSURE</h3>

<p class="simon">From time to time, Guaranteed Rate, Inc may be required by law to provide to you certain written notices or disclosures. Described below are the terms and conditions for providing to you such notices and disclosures electronically via Guaranteed Rate’s Transfersafe electronic document system, DocuSign, or via email. The words "the Company," "we," "us," and "our" refer to Guaranteed Rate, Inc. The words "I/We", "you" and "your" mean you, the individual(s) identified on the loan application or accounts. "Communication" means any applicant agreements, authorizations, amendments, disclosures, notices, responses to claims, transaction history, privacy policies and all other information related to the product or service, including but not limited to information that we are required by law to provide to you in writing. Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to these terms and conditions, please confirm your agreement by clicking the 'I agree' or the ‘I/We agree’ button within the email request received or by clicking Continue if in our DocuSign electronic document system.</p>

<h4 class="simon">Communications In Writing.</h4>

<p class="simon">All Communications, either electronic or paper format, from us to you will be considered "in writing." You should print or download for your records a copy of this Agreement and any other Communication that you deem important.</p>

<h4 class="simon">Scope Of Communications To Be Provided Electronically</h4>

<p class="simon">We may provide you with any Communications that we may choose to make available in electronic format, to the extent allowed by law, and that we may discontinue sending you paper Communications, unless and until you withdraw your consent as described below. You consent to receive electronic Communications and transactions includes, but is not limited to:</p>
<ul class="simon">
  <li class="simon">All legal and regulatory disclosures and communications associated with your loan application with the Company.</li>
  <li class="simon">All required authorizations, acknowledgements, and other documents that may be required to be provided to you or made available to you during the course of our relationship with you.</li>
  <li class="simon">All other communications about products and services available through the Company and its affiliates.</li>
  <li class="simon">Notices and disclosures about a change in the terms and conditions associated with the products and services available through the Company.</li>
  <li class="simon">Privacy policies and notices.</li>
</ul>

<h4 class="simon">Method Of Providing Communications To You In Electronic Format.</h4>

<p class="simon">All Communications that we provide to you in electronic form will be provided either: (1) via email; (2) by access to a website that we will designate in an email notice we send to you at the time the information is available; (3) by access to a website that we will generally designate in advance for such a purpose, to the extent permitted by law; or (4) by requesting that you download a PDF file containing the Communication.</p>

<h4 class="simon">Getting Paper Copies</h4>

<p class="simon">We will not send you a paper copy of any Communication unless you request it or we otherwise deem it necessary. At any time, you may request from us a paper copy of any record provided or made available electronically to you by the Company. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable period of time after we have provided you the electronic Communication. You may obtain a paper copy by submitting such a request via email or telephone to your loan originator. We reserve the right, but assume no obligation, to provide a paper copy of any Communication (in lieu of an electronic version) that you have authorized us to provide electronically.</p>

<h4 class="simon">Withdrawing Your Consent</h4>

<p class="simon">If you decide to receive Communications from us electronically, you may at any time change your mind and tell us that thereafter you want to receive the Communications only in paper format.</p>

<h5 class="simon">Via Email:</h5>

<p class="simon">To inform us that you no longer want to receive Communications in electronic format you may send us an e mail to compliance@rate.com and in the body of such request you must state your e mail, full name, US Postal Address, and telephone number. We do not need any other information from you to withdraw consent.</p>

<h5 class="simon">Via DocuSign:</h5>

<p class="simon">To indicate to us that you are changing your mind, you must withdraw your consent using the DocuSign 'Withdraw Consent' form on the signing page of your DocuSign account. This will indicate to us that you have withdrawn your consent to receive required notices and disclosures electronically from us and you will no longer be able to use your DocuSign Express user account to receive required notices and consents electronically from us or to sign electronically documents from us.</p>

<h5 class="simon">Via US Postal Service:</h5>

<p class="simon">Guaranteed Rate, Inc Attn: Compliance 3940 N Ravenswood Ave Chicago, IL 60613</p>

<p class="simon">The consequences of your withdrawing consent for electronic Communications will be that transactions may take a longer time to process. By not providing your consent, you may not be eligible for a Digital Mortgage credit, if applicable. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal.</p>

<h4 class="simon">Consequences Of Changing Your Mind</h4>

<p class="simon">If you elect to receive required Communications only in paper format, it will slow the speed at which we can complete certain steps in transactions with you and delivering services to you because we will need first to send the required Communications to you in paper format, and then wait until we receive back from you your acknowledgment of your receipt of such paper Communications.</p>

<h4 class="simon">How To Update Your Records</h4>

<p class="simon">It is your responsibility to provide us with true, accurate, and complete email address, contact, and other information related to this transaction and this Agreement, and to maintain and update promptly any changes in this information. To update information (such as your email address), contact your loan officer or send an email message to us at dnc@guaranteedrate.com and in the body of such request you must state: your previous e mail address, your new e mail address and any other relevant update.</p>

<h4 class="simon">Federal E Sign Consent</h4>

<p class="simon">You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you electronically.</p>

<h4 class="simon">Required Hardware and Software</h4>

<p class="simon">In order to access, view, and retain electronic Communications that we make available to you, you must have:</p>
<table class="simon">
  <tr class="simon">
    <td class="simon">Personal Computer</td>
    <td class="simon">A personal computer (PC: Pentium 120 Hhz or higher; for Macintosh: Power Mac 9500Power PC 604 processor 120 MHz base or higher)</td>
  </tr>
  <tr class="simon">
    <td class="simon">Operating Systems:</td>
    <td class="simon">Windows® 2000, Windows® XP, Windows Vista®; Mac OS® X with a telecommunications connections to the internet capable of receiving, accessing, displaying, and either printing or storing Communications received electronically via plan text­format or by access to our website using one of the browsers listed below.</td>
  </tr>
  <tr class="simon">
    <td class="simon">Browsers:</td>
    <td class="simon">Final release versions of Internet Explorer® 10.0 or above (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac); SafariTM 3.0 or above (Mac only)</td>
  </tr>
  <tr class="simon">
    <td class="simon">PDF Reader:</td>
    <td class="simon">Acrobat&reg; or similar software may be required to view and print PDF files</td>
  </tr>
  <tr class="simon">
    <td class="simon">Screen Resolution:</td>
    <td class="simon">800 x 600 minimum</td>
  </tr>
  <tr class="simon">
    <td class="simon">Enabled Security Settings:</td>
    <td class="simon">Allow per session cookies<br>Users accessing the internet behind a Proxy Server must enable HTTP 1.1 settings via proxy connection</td>
  </tr>
  <tr class="simon">
    <td class="simon">Email Account</td>
    <td class="simon">An email account with an Internet Service Provider and email software</td>
  </tr>
  <tr class="simon">
    <td class="simon">Sufficient Storage</td>
    <td class="simon">Sufficient electronic storage capacity on your computer’s hard drive or other data storage unit</td>
  </tr>
</table>
<p class="simon">** These minimum requirements are subject to change. If these requirements change, you will be asked to re­ accept this Agreement. Pre­release (e.g. beta) versions of operating systems and browsers are not supported.</p>

<h4 class="simon">Acknowledging Your Access And Consent To Receive Materials Electronically</h4>

<p class="simon">To confirm to us that you can access this information electronically, which will be similar to other electronic Communications that we will provide to you, please verify that you were able to read this electronic disclosure and that you also were able to print on paper or electronically save this page for your future reference and access or that you were able to email this disclosure and consent to an address where you will be able to print on paper or save it for your future reference and access. Further, if you consent to receiving Communications exclusively in electronic format on the terms and conditions described above, please let us know by clicking the ‘I Agree’ or the ‘I/We Agree’button within the email request received or by clicking Continue if in our DocuSign electronic document system.</p>

<p class="simon">By checking the ‘I Agree’ or the ‘I/We Agree’ button or by clicking Continue, I confirm that:</p>
<ul class="simon">
  <li class="simon">I/We can access and read this Electronic CONSENT TO ELECTRONIC RECEIPT OF ELECTRONIC RECORD AND SIGNATURE DISCLOSURES document; and</li>
  <li class="simon">I/We can print on paper the disclosure or save or send the disclosure to a place where I/We can print it, for future reference and access; and</li>
</ul>

<p class="simon">Until or unless I notify Guaranteed Rate, Inc as described above, I consent to receive from exclusively through electronic means all notices, disclosures, authorizations, acknowledgements, and other documents that are required to be provided or made available to me by Guaranteed Rate, Inc during the course of my relationship with you.</p>
`;

export default ModalsStyleguide;
