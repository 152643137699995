import { useHistory } from 'react-router-dom';
import { Loan } from '../../../../features/loans/types';
import { PATHS } from '../../../routes/paths';
import { useEligibilityModalContent } from '../../../../hooks/useEligibilityModalContent';
import { selectUserLoanAttributesByLoan, updateUserLoanAttribute } from '../../../../features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { useEffect, useRef, useState } from 'react';
import timestamp from '../../../../utils/timestamp';
import houseImage from '../../../../images/eligibility-house.png';
import Button from '../../../ui/button/Button';
import Modal from '../../../ui/modal/Modal';
import { sendEvent } from '../../../../features/analytics/sendEvent';
import { log } from '../../../../utils/logger';
import { WELCOME_SHOWN_KEY } from '../welcome/Welcome';
import { isBusinessHours } from '../../../../utils/isBusinessHours';

export const EligibilityModalContent = ({
  bannerImage,
  bannerMobileImage,
  bannerText,
  bodyText,
  onContinue,
} : {
  bannerImage: string;
  bannerMobileImage: string;
  bannerText: string;
  bodyText: string;
  onContinue: () => void;
}) => {
  return (
    <div className='h-full lg:h-fit-content flex flex-col'>
      <div className='flex bg-eligibility-banner justify-center md:justify-start -mx-6 lg:-mx-6 -mt-12 pl-1 md:pl-6 pt-10 pb-8 md:pt-8 md:pb-0'>
        <div className='flex items-center'>
          <img className='hidden md:block' width='414' height='97' alt={bannerText} src={bannerImage} />
          <img aria-hidden={true} className='block md:hidden' width='310' height='77' alt={bannerText} src={bannerMobileImage} />
        </div>
        <div aria-hidden={true} className='hidden md:flex flex-grow justify-end'>
          <img alt='' src={houseImage} width='349' height='156' />
        </div>
      </div>
      <div className='h-full flex flex-col pt-8'>
        <p className='text-xl' dangerouslySetInnerHTML={{ __html: bodyText }} />
        <div className='h-full lg:h-fit-content flex flex-col-reverse lg:flex-col pt-6 justify-between'>
          <Button className='w-full lg:w-fit-content' buttonType='primary' onClick={onContinue}>
            <span className='px-5'>Continue</span>
          </Button>
          <p className='text-sm self-start lg:self-end'>Business hours are M-F 8:30 to 6:30 CST</p>
        </div>
      </div>
    </div>
  );
};

export const Eligibility = ({ loan }: { loan: Loan }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { 'loan-guid': loanGuid, 'same-day-status': sameDayStatus } = loan;
  const modalContent = useEligibilityModalContent(loan, isBusinessHours());
  const [open, setOpen] = useState(false);
  const modalOpened = useRef<boolean>(false);
  const welcomeShown = !!useAppSelector(state => selectUserLoanAttributesByLoan(state, loanGuid))?.[WELCOME_SHOWN_KEY];
  // This modal has combine logic in Overview - Eligibility trumps Welcome if same day status is
  // 'not-eligible' or 'prequal' or 'eligible' or 'approved'
  // The modal shows if welcome is not shown OR 'not-eligible' trumps welcome
  const shouldShow = !welcomeShown || sameDayStatus === 'not-eligible';

  useEffect(() => {
    if (shouldShow && !open && !modalOpened.current) {
      modalOpened.current = true;
      setOpen(true);
      dispatch(updateUserLoanAttribute({ loanGuid, value: { [WELCOME_SHOWN_KEY]: timestamp() } }));
    }
  }, [dispatch, loanGuid, open, shouldShow]);

  const redirectToLoanCenter = () => {
    history.replace(PATHS.loanCenter);
  };

  const onClose = () => {
    if (sameDayStatus === 'not-eligible') {
      redirectToLoanCenter();
    } else {
      setOpen(false);
    }
  };

  const onOpen = () => {
    sendEvent('eligibilityModalShown', { loanGuid });
    log({ loanGuid, message: `Eligibility modal shown ${JSON.stringify({ sameDayStatus })}` });
  };

  if (!modalContent) {
    return null;
  }

  return (
    <Modal
      className='h-full lg:h-fit-content'
      contentClassName='h-full'
      contentVerticalClassName='h-full lg:pb-4-important'
      contentHorizontalClassName='h-full md:max-w-full lg:px-6-important'
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onRequestClose={onClose}
      contentLabel='Eligibility Modal'
      closeButtonBg={false}
    >
      <EligibilityModalContent
        bannerImage={modalContent.bannerImage}
        bannerMobileImage={modalContent.bannerMobileImage}
        bannerText={modalContent.bannerText}
        bodyText={modalContent.bodyText}
        onContinue={onClose}
      />
    </Modal>
  );
};
